import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {LoginSocialGoogle} from 'reactjs-social-login';

import {
  GOOGLEID,
  GOOGLE_SECRET_KEY,
  GOOGLE_REDIRECT_URI,
  REDIRECT_URI,
  LI_CLIENT_ID,
} from '@constant';
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import {useLinkedIn} from 'react-linkedin-login-oauth2';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

import {
  socialRegisterorLogin,
  sendVerificationemail,
  sendPasswordemail,
  getlinkedinuserEmail,
  getlinkedinuserAccessToken,
  linkedinrefreshdata,
  registrationConfirmationemail,
  validateDomain,
  validateEmailForAppUser,
  // getLinkedinemail,
  //   resetStore,
  //   isForgotPasswordOpen,
  //   validateSocialId,
  //   socialSignupAvailable,
} from '@mainActions';

// import {LinkedInLoginButton} from 'react-social-login-buttons';
import _ from 'lodash';

import {withCookies, Cookies} from 'react-cookie';
import {ENV, sessionCookieName} from '../../common/constants';

//const REDIRECT_URI = 'https://app.kipplo.com/';
// const CLIENT_ID = '86kl703as6nm9y';
// const client_secret = 'Bb074btSewBvUFj7';

// const CLIENT_ID = '8643d8ww1h8elr';
// const client_secret = 'J731XsQvYj9Rtpd9';

class SocialLogin extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      showerror: false,
      show: false,
      fbloader: false,
      signup: false,
      btnLoader: false,
      isBtnDisable: false,
      btnlinkedinLoader: false,
      islinkedinBtnDisable: false,
      provider: '',
      profile: null,
      user: {},
      loggedIn: false,
      provider: null,
      profile: {},
      processing: false,
    };
  }

  componentDidMount = () => {
    if (window.opener && window.opener !== window) {
      const code = this.getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({type: 'code', code: code}, '*');
      if (code) {
        window.close();
      }
    }
    window.addEventListener('message', this.handlePostMessage, false);
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePostMessage, false);
  }

  handlePostMessage = event => {
    if (event.data.type === 'code' && !this.state.processing) {
      this.setState({processing: true});
      const {code} = event.data;
      this.getUserCredentials(code);
    } else {
      this.setState({processing: false});
    }
  };

  getCodeFromWindowURL = url => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('code');
  };

  showPopup = () => {
    // const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LI_CLIENT_ID}&scope=r_liteprofile%20r_emailaddress&state=123456&redirect_uri=${REDIRECT_URI}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      'Linkedin',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    );
  };

  getUserCredentials = code => {
    let values = {
      code: code,
      websource: this.props.comesFrom,
      env: ENV === 'dev' ? 'dev' : 'prod',
    };
    // console.log("code", code);
    this.setState({btnlinkedinLoader: true});
    this.props.getlinkedinuserAccessToken(values, callback => {
      // console.log("call", callback);
      if (callback.success == 1 && callback.data) {
        let val = callback.data.data;

        this.props.linkedinrefreshdata(val, callbackresponse => {
          // console.log('callbackresponse', callbackresponse)
          if (callbackresponse && callbackresponse.success == 1) {
            if (callbackresponse.action && callbackresponse.action == 'login') {
              this.setState({
                btnlinkedinLoader: false,
                isBtnDisable: false,
                processing: false,
              });
              toast.success(LocaleStrings.login_success);

              const {cookies, session} = this.props;
              cookies.set(sessionCookieName, session.sessionToken);

              this.props.history.push('/dashboard/main/dashboardmain');
            } else {
              toast.success(LocaleStrings.login_success);
              this.setState({
                btnlinkedinLoader: false,
                isBtnDisable: false,
                processing: false,
              });
              this.props.history.push('/tellusmore');

              let emailobj = {};

              if (
                val.resource &&
                val.resource.length > 0 &&
                val.resource[0].clientdata
              ) {
                let userdata = val.resource[0]?.clientdata;
                emailobj.email = userdata.email;
                if (emailobj.email != '' && emailobj.email != null) {
                  this.props.registrationConfirmationemail(
                    this.props.session,
                    emailobj,
                  );
                }
              }
            }
          } else {
            this.setState({
              btnlinkedinLoader: false,
              isBtnDisable: false,
              processing: false,
            });
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else if (callback.success == 2) {
        this.setState({btnlinkedinLoader: false, processing: false});
        toast.error(LocaleStrings.sociallogin_error_emailnotexist);
      } else {
        toast.error(LocaleStrings.common_fail_message + '! Please try again');
        this.setState({btnlinkedinLoader: false, processing: false});
      }
    });
  };

  handleTokenExchange = async code => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        code,
        client_id: GOOGLEID,
        client_secret: GOOGLE_SECRET_KEY, // Replace with your Google client secret
        redirect_uri: GOOGLE_REDIRECT_URI, // Replace with your redirect URI
        grant_type: 'authorization_code',
      });

      const {id_token, access_token} = response.data;
      const profile = jwtDecode(id_token);
      //console.log('profile', profile);
      this.onSocialLoginResolve(this.state.provider, profile);
    } catch (error) {
      console.error('Error exchanging token:', error);
    }
  };

  onSocialLoginResolve = (provider, data) => {
    let {session} = this.props;

    // console.log('provider => ', provider);
    // console.log('data => ', data);

    // debugger;
    if (data && provider) {
      this.setState({isBtnDisable: true});
      this.setState({btnLoader: true});
      let values = {};
      values.email = data.email;
      values.socialid = data.sub;
      values.name = data.name;
      values.type = provider;

      this.props.validateDomain(
        this.props.session,
        {email: values.email},
        response => {
          if (
            response.result &&
            response.success === 1 &&
            response.result.valid === 1
          ) {
            if (this.props.comesFrom == 'login') {
              this.props.validateEmailForAppUser(
                this.props.session,
                {email: values.email},
                emailresponse => {
                  if (
                    emailresponse.result &&
                    emailresponse.success == 1 &&
                    emailresponse.result.available == 0
                  ) {
                    let emailobj = {};
                    emailobj.email = data.email;
                    emailobj.name = data.name;
                    this.props.socialRegisterorLogin(
                      values,
                      callbackresponse => {
                        if (
                          callbackresponse &&
                          callbackresponse.success === 1
                        ) {
                          if (
                            callbackresponse.action &&
                            callbackresponse.action === 'login'
                          ) {
                            this.setState({isBtnDisable: false});
                            this.setState({btnLoader: false});
                            toast.success(LocaleStrings.login_success);

                            const {cookies, session} = this.props;
                            cookies.set(
                              sessionCookieName,
                              session.sessionToken,
                            );

                            this.props.history.push(
                              '/dashboard/main/dashboardmain',
                            );
                            // this.props.registrationConfirmationemail(
                            //   this.props.session,
                            //   emailobj
                            // );
                          } else {
                            toast.success(LocaleStrings.login_success);
                            this.setState({isBtnDisable: false});
                            this.setState({btnLoader: false});
                            this.props.history.push('/tellusmore');
                            this.props.registrationConfirmationemail(
                              this.props.session,
                              emailobj,
                            );
                            // this.props.sendPasswordemail(session, emailobj);
                            // this.props.sendVerificationemail(session, emailobj);
                            // this.props.history.push('/dashboard/main/dashboardmain');
                          }
                        } else {
                          this.setState({isBtnDisable: false});
                          this.setState({btnLoader: false});
                          toast.error(LocaleStrings.common_fail_message);
                        }
                      },
                    );
                  } else {
                    this.setState({isBtnDisable: false});
                    this.setState({btnLoader: false});
                    toast.error(LocaleStrings.sociallogin_error_emailnotexist);
                  }
                },
              );
            } else {
              let emailobj = {};
              emailobj.email = data.email;
              emailobj.name = data.name;
              this.props.socialRegisterorLogin(values, callbackresponse => {
                if (callbackresponse && callbackresponse.success === 1) {
                  if (
                    callbackresponse.action &&
                    callbackresponse.action === 'login'
                  ) {
                    this.setState({isBtnDisable: false});
                    this.setState({btnLoader: false});
                    toast.success(LocaleStrings.login_success);

                    const {cookies, session} = this.props;
                    cookies.set(sessionCookieName, session.sessionToken);

                    this.props.history.push('/dashboard/main/dashboardmain');
                    // this.props.registrationConfirmationemail(
                    //   this.props.session,
                    //   emailobj
                    // );
                  } else {
                    toast.success(LocaleStrings.login_success);
                    this.setState({isBtnDisable: false});
                    this.setState({btnLoader: false});
                    this.props.history.push('/tellusmore');
                    this.props.registrationConfirmationemail(
                      this.props.session,
                      emailobj,
                    );
                    // this.props.sendPasswordemail(session, emailobj);
                    // this.props.sendVerificationemail(session, emailobj);
                    // this.props.history.push('/dashboard/main/dashboardmain');
                  }
                } else {
                  this.setState({isBtnDisable: false});
                  this.setState({btnLoader: false});
                  toast.error(LocaleStrings.common_fail_message);
                }
              });
            }
          } else {
            this.setState({isBtnDisable: false});
            this.setState({btnLoader: false});
            toast.error(LocaleStrings.business_email_error);
          }
        },
      );
    } else {
    }
  };

  onLoginStart = () => {};
  linkedInLogin = linkedInLogin => {
    // console.log("eee", linkedInLogin);
    // alert("hi");
  };

  render() {
    var {handleSubmit} = this.props;
    var {signup, provider, data} = this.state;

    return (
      <div className="w-full">
        <div className="gap-2 flex flex-col">
          {/* <LoginSocialGoogle
            client_id={GOOGLEID}
            onLoginStart={this.onLoginStart}
            scope="openid profile email https://www.googleapis.com/auth/contacts.readonly"
            discoveryDocs={[
              'https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest',
            ]}
            access_type="offline"
            responseType="code"
            onResolve={({provider, data}) => {
              this.setState({provider: provider});
              this.handleTokenExchange(data.code);
            }}
            onReject={err => {
              console.log(err);
            }}>
            <button
              type="button"
              disabled={this.state.isBtnDisable}
              className={classNames(
                this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                'btn-social-login',
              )}>
              <svg
                className={classNames(
                  this.state.btnLoader ? '' : 'sr-only',
                  'animate-spin-medium h-5 w-5 rounded-full mx-2',
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              <img className="pr-2" src="../../images/google.png"></img>{' '}
              {this.props.comesFrom === 'register'
                ? LocaleStrings.mainsignup_signupgoogle
                : LocaleStrings.mainlogin_signingoogle}
            </button>
          </LoginSocialGoogle> */}

          <div onClick={this.showPopup}>
            <button type="button" className="btn-social-login">
              <svg
                className={classNames(
                  this.state.btnlinkedinLoader ? '' : 'sr-only',
                  'animate-spin-medium h-5 w-5 rounded-full mx-2',
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              <img className="pr-2" src="../../images/linkedin.png"></img>{' '}
              {this.props.comesFrom === 'register'
                ? LocaleStrings.mainsignup_signuplinkedin
                : LocaleStrings.mainlogin_signinlinkedin}
            </button>
          </div>

          <Toaster />
        </div>
        {this.state.profile
          ? Object.entries(this.state.profile).map(([key, value]) => (
              <div className="field" key={key}>
                <div className="label">{key}: </div>
                <div className="value">{JSON.stringify(value)}</div>
              </div>
            ))
          : ''}
      </div>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default reduxForm({
  validate,
  form: 'SocialLoginForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    socialRegisterorLogin,
    sendVerificationemail,
    sendPasswordemail,
    getlinkedinuserEmail,
    getlinkedinuserAccessToken,
    linkedinrefreshdata,
    registrationConfirmationemail,
    validateDomain,
    validateEmailForAppUser,
  })(withCookies(SocialLogin)),
);
